import React from "react";
import { Link } from "gatsby";
import AOS from "aos";

import imageOne from "../../assets/image/home-4/png/l4-hero-desktops-img.png";
import imageTwo from "../../assets/image/home-4/png/l4-hero-phone-img.png";

const Hero = ({ className, ...rest }) => {
  setTimeout(() => {
    AOS.refresh();
  }, 500);

  return (
    <div className={className} {...rest}>
      <div className="container position-static">
        <div className="row position-static">
          <div className="col-xl-8 col-lg-8 col-md-10 col-sm-12">
            <div
              className="mt-8 mt-lg-0 pt-lg-16 me-xl-28 me-lg-10"
              data-aos="fade-right"
              data-aos-duration={600}
              data-aos-once="true"
            >
              <h1 className="font-size-11 mb-12 line-height-84 pe-15 pe-lg-0">
                We make apps.
              </h1>
              <p className="mb-12">
                Contact us to learn more.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
